import React, { useState } from 'react';

import Posts from '../../Posts';
import Benefits from '../shared/Product/Benefits';
import BigNumbers from '../shared/Product/BigNumbers';
import Footer from '../../../components/Layout/Footer';
import TextSection from '../shared/Product/TextSection';
import TriggerAction from '../shared/Product/TriggerAction';
import SloganBenefits from '../shared/Product/SloganBenefits';
import ExactaFlowTrigger from '../shared/Product/ExactaFlowTrigger';
import { Solutions as SolutionIcon } from '../../../components/Icons/Values/solutions';
import { Header } from '../../../components/Layout/Header';
import { EssentialValues } from './EssentialValues';
import { ContactForm } from '../shared/ContactForm';
import { Exactalovers } from './Exactalovers';
import { Highlights } from './Highlights';
import { Solutions } from './Solutions';
import { Company } from './Company';
import { Clients } from './Clients';
import { Banner } from './Banner';

import gradient from '../../../../static/images/gradient.svg';

import * as S from './styles';

export function Home() {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Header open={open} setOpen={setOpen} />

      <S.Main open={open} setOpen={setOpen}>
        <S.Gradient src={gradient} alt="Imagem de gradiente colorido" />

        <Banner />

        <TextSection
          heading={
            <>
              Fazemos{' '}
              <span className="color--green italic semi-bold">muito mais</span>{' '}
              do que software. Transformamos negócios com soluções tecnológicas
              de{' '}
              <span className="color--green italic semi-bold">
                alta performance.
              </span>
            </>
          }
          texts={[
            'Nascemos com o objetivo de elevar empresas ao futuro do digital, com a força de pessoas aceleradas por novas tecnologias, como a Inteligência Artificial. ',
            'Escalamos e desenvolvemos soluções tecnológicas de alto impacto, 100% sob medida, por meio da alocação de times com profissionais multidisciplinares.',
            'Em seis anos de história, já são mais de 35 milhões de usuários impactados pelas nossas soluções de hiper eficiência e com resultados sólidos para cada cliente atendido. '
          ]}
        />

        <Highlights />

        <Solutions />

        <TriggerAction
          text={
            <>
              Agende agora sua{' '}
              <span className="semi-bold">análise personalizada</span> e
              conquiste mais eficiência nos seus times de tecnologia
            </>
          }
          action={{
            label: 'Fale com a gente',
            href: '#contato'
          }}
        />

        <Clients />

        <Benefits
          heading={
            <>
              Por que contar com a expertise da{' '}
              <span className="semi-bold italic">Exactaworks</span>?
            </>
          }
          items={[
            {
              icon: <SolutionIcon />,
              heading: 'Eficiência operacional e foco no core business',
              text: 'Desenvolvemos seus produtos digitais dando o reforço que seu time interno precisa para focar nas atividades essenciais para a sua empresa.'
            },
            {
              icon: <SolutionIcon />,
              heading: 'Economia de recursos',
              text: 'Reduza tempo e custos de novas contratações sem precisar adiar seus projetos tecnológicos por falta de profissionais qualificados.'
            },
            {
              icon: <SolutionIcon />,
              heading: 'Frameworks exclusivos e de alta performance',
              text: 'Metodologia própria estruturada e testada, integrada ao uso de ferramentas de gestão de produtividade e melhores softwares do mercado.'
            },
            {
              icon: <SolutionIcon />,
              heading: 'Gestão de times realizada pela Exactaworks',
              text: 'Liderança Tech fundamentada na O2, garantindo máxima produtividade, engajamento, baixo turnover e realocação de profissionais rápida e segura.'
            },
            {
              icon: <SolutionIcon />,
              heading: 'Foco na satisfação do cliente',
              text: 'Serviços sob medida e atendimento personalizado de acordo com a necessidade e as demandas da empresa.'
            },
            {
              icon: <SolutionIcon />,
              heading: 'Soluções e decisões orientadas por dados',
              text: 'Acompanhamento de métricas de eficiência e relatórios de produtividade, com definições de OKRs, KPIs e oportunidades para escalar resultados.'
            },
            {
              icon: <SolutionIcon />,
              heading:
                'Maior adaptabilidade para inovação e transformação digital',
              text: 'Profissionais multidisciplinares certificados em Inteligência Artificial, com desenvolvimento de soluções e tomada de decisões orientadas por dados. '
            },
            {
              icon: <SolutionIcon />,
              heading: 'Acesso a profissionais de todo o universo tech',
              text: 'Tech Leads, CTOs, Agilistas, Scrum Masters, Product Manager, Product Owner, Front-end, Back-end, UI e UX Designers, Devs, e muito mais!'
            }
          ]}
        />

        <Company />

        <TriggerAction
          newTab={false}
          text={
            <>
              Impulsione o desenvolvimento da sua empresa e conquiste{' '}
              <span className="semi-bold">
                soluções tecnológicas inovadoras
              </span>{' '}
              agora mesmo
            </>
          }
          action={{
            label: 'Fale com a gente',
            href: '#contato'
          }}
        />

        <SloganBenefits />

        <BigNumbers
          items={[
            {
              label: 'mais rápido em tarefas repetitivas',
              value: '96%'
            },
            {
              label: 'maior confiança em qualidade de código',
              value: '85%'
            },
            {
              label: 'maior agilidade em code reviews',
              value: '15%'
            },
            {
              label: 'maior velocidade de maneira geral',
              value: '55%'
            }
          ]}
        />

        <ExactaFlowTrigger />

        <Exactalovers />

        <EssentialValues />

        <Posts />

        <ContactForm
          title={
            <>
              Está em busca de um{' '}
              <span className="color--pink semi-bold italic">
                parceiro ideal
              </span>{' '}
              para{' '}
              <span className="color--green semi-bold italic">
                desenvolver soluções tecnológicas
              </span>{' '}
              com excelência?
            </>
          }
          description="Com a Exactaworks, sua empresa conta com o apoio de profissionais capacitados em todas as etapas desse processo – desde o amadurecimento de uma ideia de produto digital até o desenvolvimento do aplicativo ou plataforma"
        />

        <Footer />
      </S.Main>
    </>
  );
}

import styled from 'styled-components';
import { device } from '../../../../styles/media';
import { Section } from '../../shared/Common';

export const SectionHighlights = styled(Section)`
  display: flex;
  overflow-y: hidden;
  justify-content: center;
  align-items: center;

  @media ${device.tablet} {
    height: 100%;
  }
`;

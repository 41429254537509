import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { StaticImage } from 'gatsby-plugin-image';

import Title from '../Title';

import * as S from './styles';

const aboutUs = '../../../static/cards/aboutUs.svg';

export function CardAboutUs() {
  const intl = useIntl();

  return (
    <S.Wrapper>
      <S.TitleContainer>
        <Title elementTag="h2" tag="h3" weight="light">
          {intl.formatMessage({ id: 'aboutUs-title-1' })}
          <span>{intl.formatMessage({ id: 'aboutUs-title-2' })}</span>
          {intl.formatMessage({ id: 'aboutUs-title-3' })}
        </Title>
      </S.TitleContainer>

      <StaticImage
        quality={100}
        src={aboutUs}
        alt={intl.formatMessage({ id: 'aboutUs-alt' })}
      />
    </S.Wrapper>
  );
}

import image1 from '../../../../static/depositions/rebeca.jpeg';

export const highlights = [
  {
    main: 'highlight-main-1'
  },
  {
    main: 'highlight-main-2'
  },
  {
    main: 'highlight-main-3'
  },
  {
    main: 'highlight-main-4'
  },
  {
    main: 'highlight-main-5'
  }
];

export const aboutUs = [
  {
    main: 'aboutUs-main-1',
    emphasis: 'aboutUs-emphasis-1',
    minor: 'aboutUs-minor-1'
  },
  {
    main: 'aboutUs-main-2',
    emphasis: 'aboutUs-emphasis-2',
    minor: 'aboutUs-minor-2'
  },
  {
    main: 'aboutUs-main-3',
    emphasis: 'aboutUs-emphasis-3',
    minor: 'aboutUs-minor-3'
  },
  {
    main: 'aboutUs-main-4',
    emphasis: 'aboutUs-emphasis-4',
    minor: 'aboutUs-minor-4'
  },
  {
    main: 'aboutUs-main-5',
    emphasis: 'aboutUs-emphasis-5',
    minor: 'aboutUs-minor-5'
  }
];

export const depositions = [
  {
    main: 'deposition-message-1',
    name: 'Rebeca Albuquerque',
    alt: 'foto da colaboradora Rebeca',
    position: 'deposition-job-position-designer',
    image: image1
  }
];

import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import Text from '../../../../Text';
import Title from '../../../../Title';
import Link from '../../../../Link';

import service1 from '../../../../../../static/cards/service1.jpg';
import service2 from '../../../../../../static/cards/service2.jpg';
import service3 from '../../../../../../static/cards/service3.jpg';
import * as S from './styles';

const services = [
  {
    title: 'services-1-title',
    messages: ['services-1-message-1', 'services-1-message-2'],
    image: service1,
    imageAlt: 'services-1-alt',
    squareColor: 'pink',
    link: '/solucoes/times-ageis'
  },
  {
    title: 'services-2-title',
    messages: ['services-2-message-1', 'services-2-message-2'],
    image: service2,
    imageAlt: 'services-2-alt',
    squareColor: 'blue',
    link: '/solucoes/projetos-e-consultoria'
  },
  {
    title: 'services-3-title',
    messages: ['services-3-message-1', 'services-3-message-2'],
    image: service3,
    imageAlt: 'services-3-alt',
    squareColor: 'green',
    link: '/solucoes/tech-recruiter'
  }
];

const Services = () => {
  const intl = useIntl();

  return (
    <S.Wrapper>
      {services.map((item, index) => {
        return (
          <S.ServicesContainer key={index}>
            <S.ContentContainer>
              <S.ImageContainer>
                <img
                  src={item.image}
                  alt={intl.formatMessage({ id: item.imageAlt })}
                />

                <S.Square className={item.squareColor} />
              </S.ImageContainer>

              <S.TitleContainer>
                <Title tag="h3">{intl.formatMessage({ id: item.title })}</Title>

                <S.Square className={item.squareColor} />
              </S.TitleContainer>
            </S.ContentContainer>

            <S.Content>
              <Title tag="h3">{intl.formatMessage({ id: item.title })}</Title>

              <S.TextWrapper>
                {item.messages.map(text => (
                  <Text key="text" size="xmedium">
                    {intl.formatMessage({ id: text })}
                  </Text>
                ))}
              </S.TextWrapper>

              <Link
                target="_blank"
                to={item.link}
                rel="nofollow noopener noreferrer"
              >
                {intl.formatMessage({ id: 'services-button' })}
              </Link>
            </S.Content>
          </S.ServicesContainer>
        );
      })}
    </S.Wrapper>
  );
};

export default Services;

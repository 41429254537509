import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import Title from '../../../Title';
import Text from '../../../Text';
import Services from './Services';

import * as S from './styles';

export function Solutions() {
  const intl = useIntl();

  return (
    <S.Wrapper id="solucoes">
      <S.TextContainer>
        <Title tag="h3" elementTag="h2" align="center">
          {intl.formatMessage({ id: 'services-title-1' })}
          <span className="color--lightblue">
            {intl.formatMessage({ id: 'services-title-2' })}
          </span>
        </Title>

        <Text tag="p" align="center" size="xmedium">
          {intl.formatMessage({ id: 'services-message' })}
        </Text>
      </S.TextContainer>

      <Services />
    </S.Wrapper>
  );
}

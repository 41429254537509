import styled, { css } from 'styled-components';
import { device } from '../../styles/media';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    max-width: 46rem;

    &::after {
      content: '';
      width: 3.6rem;
      height: 3.6rem;
      background-color: ${theme.colors.green};
      position: absolute;
      right: 0;
      bottom: 0;
    }

    @media ${device.laptop} {
      &::after {
        position: initial;
        margin-left: auto;
        width: 5rem;
        height: 5rem;
      }
    }
  `}
`;

export const TitleContainer = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.darkblue};
    padding: 2rem 2.5rem;

    span {
      color: ${theme.colors.green};
      font-weight: ${theme.font.bold};
      font-style: italic;
    }
  `}
`;

export const Image = styled.img`
  width: 100%;
`;

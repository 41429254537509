import styled, { css } from 'styled-components';
import { device } from '../../../../styles/media';
import { Section } from '../../shared/Common';

export const Company = styled(Section)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 5rem 10rem;
`;

export const List = styled.ul`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    font-size: ${theme.font.sizes.medium};
    font-weight: ${theme.font.light};
    color: ${theme.colors.white};
    min-height: 10rem;
    max-width: 56rem;
    list-style-type: none;

    span {
      color: ${theme.colors.white};
      font-weight: ${theme.font.bold};
      font-style: italic;
    }

    @media ${device.laptop} {
      font-size: ${theme.font.sizes.xxmedium};
      gap: 5.2rem;
      margin-top: 4rem;
    }
  `};
`;

export const Item = styled.div`
  display: inline-grid;
  gap: 1rem;
  grid-template-columns: 2rem 1fr;

  @media ${device.laptop} {
    gap: 1.6rem;
  }
`;

export const Marker = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.pink};
    width: 1.6rem;
    height: 1.6rem;
    margin-top: 0.4rem;

    @media ${device.laptop} {
      width: 2rem;
      height: 2rem;
    }
  `};
`;

import styled, { css } from 'styled-components';
import { device } from '../../../../styles/media';
import { Section } from '../../shared/Common';

export const Wrapper = styled(Section)`
  ${({ theme }) => css`
    display: flex;
    text-align: center;
    flex-direction: column;
    font-style: italic;
    gap: 2rem;

    span {
      color: ${theme.colors.green};
    }

    @media ${device.laptop} {
      p {
        width: 76rem;
      }
    }
  `};
`;

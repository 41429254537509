import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import { Conections } from '../../../../Icons/Values/conections';
import { Support } from '../../../../Icons/Values/suport';
import { People } from '../../../../Icons/Values/people';
import { Idea } from '../../../../Icons/Values/idea';
import { Solutions } from '../../../../Icons/Values/solutions';
import { Teamwork } from '../../../../Icons/Values/teamwork';

import * as S from './styles';

const valuesLogos = [
  {
    name: 'connections-logo',
    image: <Conections />,
    text: 'essential-values-1'
  },
  {
    name: 'respect-logo',
    image: <People />,
    text: 'essential-values-2'
  },
  {
    name: 'learn-logo',
    image: <Idea />,
    text: 'essential-values-3'
  },
  {
    name: 'partner-logo',
    image: <Support />,
    text: 'essential-values-4'
  },
  {
    name: 'solutions-logo',
    image: <Solutions />,
    text: 'essential-values-5'
  },
  {
    name: 'teamwork-logo',
    image: <Teamwork />,
    text: 'essential-values-6'
  }
];
const ValuesLogo = () => {
  const intl = useIntl();

  return (
    <S.Wrapper>
      {valuesLogos.map((item, index) => (
        <S.ValuesLogo key={index}>
          {item.image}

          <span> {intl.formatMessage({ id: item.text })}</span>
        </S.ValuesLogo>
      ))}
    </S.Wrapper>
  );
};

export default ValuesLogo;

import styled, { css, keyframes } from 'styled-components';
import underline from '../../../../../static/images/underline.svg';
import underlinemobile from '../../../../../static/images/underline-mobile.svg';
import { device } from '../../../../styles/media';
import { Section } from '../../shared/Common';

const animate = keyframes`
  0% {
    content: 'creative';
  }

  50% {
    content: 'engaged';
  }

  75% {
    content: 'connected';
  }
`;

export const Wrapper = styled(Section)`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    padding: 6.4rem 0 0;
    height: fit-content;
    min-height: 54rem;
    max-width: 100vw;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media ${device.tablet} {
      min-height: 58rem;
    }

    @media ${device.laptop} {
      min-height: 90vh;
    }
  `};
`;

export const AnimateText = styled.span`
  background: url(${underlinemobile}) no-repeat;
  background-position: center 100%;

  ::before {
    content: 'amazing';
    animation: 8s ${animate} infinite;
  }

  @media ${device.laptop} {
    background: url(${underline}) no-repeat;
    background-position: center 100%;
    width: 37.6rem;
  }
`;

export const EmphasisText = styled.span`
  ${({ theme }) => css`
    font-weight: ${theme.font.bold};
    font-style: italic;
  `};
`;

export const ContentWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    max-width: ${theme.grid.container};
    font-family: ${theme.font.family};
    margin: 0 auto;
    padding: 4rem 8%;
    z-index: 1;
  `};
`;

export const TitleContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    height: fit-content;
    width: 30rem;

    h1 {
      align-self: flex-end;
      font-weight: ${theme.font.light};
      margin: 0;
      color: ${theme.colors.white};
      z-index: 1;
    }

    @media ${device.tablet} {
      width: auto;
    }
  `};
`;

export const Subtitle = styled.h2`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.medium};
    color: ${theme.colors.lightgray};
    font-weight: ${theme.font.normal};
    margin: 1.2rem 0;
    z-index: 1;

    @media ${device.tablet} {
      font-size: ${theme.heading.sizes.h5};
    }
  `};
`;

export const ButtonWrapper = styled.div`
  margin-top: 8rem;
  position: relative;
  bottom: 0rem;
  gap: 1.6rem;
  display: flex;
  flex-direction: column-reverse;
  width: 26rem;
  align-self: center;

  @media ${device.tablet} {
    flex-direction: row;
    width: fit-content;
  }

  @media ${device.laptop} {
    margin-top: 9.8rem;
    align-self: start;
  }
`;

export const Image = styled.img`
  z-index: 0;
`;

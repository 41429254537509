import styled, { css } from 'styled-components';
import { device } from '../../../styles/media';
import { MenuProps } from '../../Layout/Header';

export const Gradient = styled.img`
  position: absolute;
  top: -28vw;
  width: 90vw;
  height: 100%;
  right: 0;
  overflow: hidden;
  object-fit: cover;
  object-position: 16% 10%;

  @media ${device.tablet} {
    top: -12rem;
    width: 59rem;
    height: min(73rem, 125vh);
  }

  @media ${device.laptop} {
    top: -20rem;
    width: 94rem;
    height: min(120rem, 125vh);
  }
`;

export const Main = styled.main<MenuProps>`
  ${({ open }) => css`
    background: ${open && 'rgba(0, 0, 0, 0.4)'};
    filter: ${open && 'blur(4px)'};
    overflow: hidden;
  `}
`;

import React from 'react';

import { highlights } from '../data';
import { SlideShow } from '../../../Carousel';

import * as S from './styles';

export function Highlights() {
  return (
    <S.SectionHighlights id="highlights">
      <SlideShow texts={highlights} size="xxlarge" weight="bold" />
    </S.SectionHighlights>
  );
}

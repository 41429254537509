import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import Title from '../../../Title';

import * as S from './styles';
import ValuesLogo from './ValuesLogo';

export function EssentialValues() {
  const intl = useIntl();

  return (
    <S.Wrapper>
      <S.Section id="values">
        <Title tag="h2" align="center">
          {intl.formatMessage({ id: 'essential-values-section' })}
        </Title>

        <ValuesLogo />
      </S.Section>
    </S.Wrapper>
  );
}

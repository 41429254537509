import styled, { css } from 'styled-components';
import { device } from '../../../../styles/media';
import { Section } from '../../shared/Common';

export const Wrapper = styled(Section)`
  display: flex;
  flex-direction: column;
  padding-left: 2rem;
  padding-right: 2rem;
  align-items: center;
  text-align: center;
  max-width: 160rem;
  gap: 2rem;
`;

export const TextContainer = styled.div`
  ${({ theme }) => css`
    align-self: center;
    padding: 0 2rem;

    span {
      font-style: italic;
      font-weight: ${theme.font.bold};
    }

    p {
      margin-top: 2rem;
      text-align: center;
    }

    @media ${device.laptop} {
      padding: 0 0 6rem;
      width: 76rem;
    }
  `}
`;

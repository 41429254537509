import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import { CardAboutUs } from '../../../CardAboutUs';
import { aboutUs } from '../data';

import * as S from './styles';

export function Company() {
  const intl = useIntl();
  return (
    <S.Company>
      <CardAboutUs />

      <S.List className="dots">
        {aboutUs.map((item, id) => (
          <S.Item key={id}>
            <S.Marker />

            <li>
              {intl.formatMessage({ id: item.main })}
              <span> {intl.formatMessage({ id: item.emphasis })}</span>
              {intl.formatMessage({ id: item.minor })}
            </li>
          </S.Item>
        ))}
      </S.List>
    </S.Company>
  );
}

import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import Title from '../../../Title';
import ClientLogo from './ClientLogo';

import * as S from './styles';

export function Clients() {
  const intl = useIntl();

  return (
    <S.Wrapper id="clientes">
      <Title
        elementTag="h2"
        tag="h6"
        color="lightblue"
        align="center"
        weight="bold"
        italic
      >
        {intl.formatMessage({ id: 'our-clients-title' })}
      </Title>

      <Title tag="h3" align="center">
        {intl.formatMessage({ id: 'our-clients-text-1' })}
        <span>{intl.formatMessage({ id: 'our-clients-text-2' })}</span>
        {intl.formatMessage({ id: 'our-clients-text-3' })}
      </Title>

      <ClientLogo />
    </S.Wrapper>
  );
}

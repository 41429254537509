import styled, { css } from 'styled-components';
import { device } from '../../../../../styles/media';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: wrap;
  flex-direction: column;
  align-items: flex-start;
  gap: 3.6rem;

  @media ${device.laptop} {
    flex-direction: row;
    align-items: initial;
    gap: 8rem;
  }
`;

export const ValuesLogo = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    gap: 2rem;

    svg {
      display: flex;
      align-self: center;
      height: 4rem;
      width: 4rem;
      color: ${theme.colors.green};
    }

    span {
      align-self: center;
      font-size: ${theme.font.sizes.medium};
      font-weight: ${theme.font.light};
    }

    @media ${device.laptop} {
      svg {
        color: ${theme.colors.pink};
        height: 5.4rem;
        width: 5.4rem;
      }

      span {
        font-size: ${theme.font.sizes.large};
        width: 18rem;
      }
    }
  `}
`;

import React from 'react';

export function ChevronRight() {
  return (
    <svg
      width="49"
      height="36"
      viewBox="0 0 49 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1051_31568)">
        <path
          d="M10.2083 18H38.7916"
          stroke="#03FFCB"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30.625 24L38.7917 18"
          stroke="#03FFCB"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30.625 12L38.7917 18"
          stroke="#03FFCB"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1051_31568">
          <rect width="49" height="36" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

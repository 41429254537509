import styled, { DefaultTheme, css } from 'styled-components';
import { Align, CarouselProps } from '.';
import { device } from '../../styles/media';

const modifiers = {
  wrapper: {
    slide: () => css`
      align-items: center;

      .dots {
        margin-top: 1.6rem;
        position: relative;
      }
    `,
    deposition: () => css`
      align-items: flex-end;

      .dots {
        margin: 3.2rem 0;
        position: absolute;
        bottom: 2rem;
      }
    `
  },
  slideContent: {
    slide: () => css`
      text-align: center;
      align-items: center;
      padding-bottom: 2rem;
      min-height: 8rem;
      height: 100%;
    `,
    deposition: () => css`
      margin: 5rem 2rem;
      text-align: left;
      height: fit-content;
      padding: 2rem 0;
    `
  },
  wrapperContent: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    slide: (_: DefaultTheme) => css`
      background-color: none;
      font-style: italic;
      height: fit-content;
      max-width: 60rem;

      @media ${device.laptop} {
        max-width: 80%;
        height: fit-content;
      }
    `,
    deposition: (theme: DefaultTheme) => css`
      padding: 5rem 3.2rem 4rem;
      background-color: ${theme.colors.darkgray};
      font-style: normal;
      max-width: auto;
      height: 44rem;

      @media ${device.laptop} {
        left: 5rem;
        min-height: 48rem;
        max-width: 72rem;
        height: 36rem;
        padding: 7.2rem;
      }
    `
  }
};

export const Wrapper = styled.div<Align & Pick<CarouselProps, 'type'>>`
  ${({ theme, align, type = 'slide' }) => css`
    display: flex;
    overflow-x: hidden;
    justify-content: center;
    position: relative;
    flex-direction: column-reverse;
    overflow: hidden;

    ${modifiers.wrapper[type]()}

    .dots {
      display: flex;
      justify-content: center;
      gap: 1.6rem;

      li {
        list-style-type: none;
      }
    }

    .dots label {
      display: inline-block;
      width: 1.2rem;
      height: 1.2rem;
      border-radius: 50%;
      border: 1px solid ${theme.colors.gray};
      background: ${theme.colors.gray};
      cursor: pointer;
    }

    .dots .active {
      background: ${theme.colors.pink};
      border: 1px solid ${theme.colors.pink};
    }

    .dots label:hover {
      background: ${theme.colors.gray};
    }

    .join-team {
      display: flex;
      align-self: flex-end;
      bottom: 3.6rem;
      position: absolute;
    }

    @media ${device.laptop} {
      flex-direction: row;

      .dots {
        align-self: ${align === 'left'
          ? 'flex-start'
          : align === 'right'
          ? 'flex-end'
          : 'center'};
      }
    }
  `};
`;

export const SliderContent = styled.div<Pick<CarouselProps, 'type'>>`
  ${({ theme, type = 'slide' }) => css`
    font-family: ${theme.font.family};
    position: relative;
    display: flex;

    h3 {
      align-self: end;
    }

    ${modifiers.slideContent[type]()}

    span {
      color: ${theme.colors.green};
    }

    @media ${device.laptop} {
      min-height: 31rem;
    }
  `};
`;

export const WrapperContent = styled.div<Pick<CarouselProps, 'type'>>`
  ${({ theme, type = 'slide' }) => css`
    display: flex;
    position: relative;
    justify-content: center;
    flex-direction: column;

    ${modifiers.wrapperContent[type](theme)}

    .quotes {
      position: absolute;
      top: 4.2rem;
      left: 4.2rem;
      width: 3rem;
      z-index: 2;
    }

    @media ${device.laptop} {
      z-index: 1;

      .quotes {
        position: absolute;
        top: 6.2rem;
        left: 6.2rem;
        width: 5rem;
        z-index: 2;
      }
    }
  `};
`;

export const DepositionWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    overflow: hidden;
    padding: 0 0 7.2rem;
    background: linear-gradient(
      to right,
      ${theme.colors.pink} 4%,
      ${theme.colors.pink} 20%,
      ${theme.colors.blue} 20%,
      ${theme.colors.blue} 85.8%
    );

    h5,
    p {
      position: absolute;
      bottom: 3.2rem;
      left: 9.4rem;
    }

    .details {
      bottom: 1.6rem;
    }

    @media ${device.laptop} {
      width: 58rem;
      height: 58rem;
      border-left: 10rem solid ${theme.colors.pink};
      padding: 0 0 10rem;

      background: linear-gradient(
        to right,
        ${theme.colors.blue} 28.6%,
        ${theme.colors.blue} 85.8%
      );

      left: -5rem;

      p {
        position: absolute;
        bottom: 4.8rem;
        left: 4.8rem;
      }

      .details {
        bottom: 2.4rem;
      }
    }
  `};
`;

export const Image = styled.img`
  width: 100%;

  @media ${device.laptop} {
    width: 48rem;
    height: 48rem;
  }
`;

export const Link = styled.div`
  display: flex;

  a {
    text-decoration: none;
    align-self: center;
  }
`;

import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import Link from '../../../Link';
import Title from '../../../Title';
import * as S from './styles';

export function Banner() {
  const intl = useIntl();

  return (
    <S.Wrapper>
      <S.ContentWrapper>
        <S.TitleContainer>
          <Title tag="h1">
            Powered by
            <br />
            <S.AnimateText />
            <S.EmphasisText> people</S.EmphasisText>
          </Title>
        </S.TitleContainer>

        <S.Subtitle>{intl.formatMessage({ id: 'banner-subtitle' })}</S.Subtitle>

        <S.ButtonWrapper>
          <Link color="white" to="#solucoes" size="large">
            {intl.formatMessage({ id: 'banner-button-services' })}
          </Link>

          <Link to="#contato" size="large">
            {intl.formatMessage({ id: 'banner-button-contact' })}
          </Link>
        </S.ButtonWrapper>
      </S.ContentWrapper>
    </S.Wrapper>
  );
}

import styled, { css } from 'styled-components';
import background from '../../../../../static/images/exactalovers-background.png';
import { device } from '../../../../styles/media';
import { SquareProps } from '.';
import { Section as CommonSection } from '../../shared/Common';

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  background: url(${background}) no-repeat;
  background-position: left bottom;
  overflow-y: hidden;
`;

export const ContentWrapper = styled(CommonSection)`
  ${({ theme }) => css`
    display: flex;
    max-width: 180px;
    flex-direction: column;
    justify-content: center;
    max-width: ${theme.grid.container};
    align-items: center;
    text-align: center;
    gap: 4rem 8rem;

    @media ${device.laptopL} {
      flex-direction: row;
      text-align: start;
    }

    span {
      color: ${theme.colors.green};
      font-weight: ${theme.font.bold};
      font-style: italic;
    }
  `};
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6rem;

  @media ${device.laptopL} {
    align-items: flex-start;

    h6 {
      align-self: start;
      font-style: normal;
    }
  }
`;

export const Mosaic = styled.div`
  max-width: 56rem;
  position: relative;
  width: auto;

  .person_square {
    width: 21vw;
    height: 20vw;
  }

  .person_rectangle {
    width: 21vw;
    height: 32vw;
  }

  @media ${device.laptop} {
    flex-direction: row;

    .person_square {
      width: 20rem;
      height: 19rem;
    }

    .person_rectangle {
      width: 20rem;
      height: 25.5rem;
    }
  }
`;

export const Line = styled.div`
  display: flex;
  gap: 1.2rem;
  justify-content: center;
  padding: 0.6rem;

  :first-child {
    align-items: end;
    align-self: end;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  justify-content: center;

  :first-child {
    align-self: end;
    align-items: end;
  }

  :nth-child(2n) {
    align-items: end;
  }

  :last-child {
    align-self: start;
  }
`;

export const ImageWrapper = styled.div`
  justify-content: center;
  align-items: center;
  padding: 0;
  overflow: hidden;

  .imgPositionX {
    transform: translateX(-20%);
    width: 250px;
    height: 190px;
  }

  @media ${device.laptop} {
    .imgPosition {
      transform: translateY(-4rem);
    }

    .imgPositionX {
      transform: translateX(-3rem);
    }
  }
`;

export const Square = styled.div<SquareProps>`
  ${({ theme, color }) => css`
    background-color: ${theme.colors[color]};
    width: 3.2rem;
    height: 3.2rem;

    @media ${device.tablet} {
      width: 4.8rem;
      height: 4.8rem;
    }

    @media ${device.laptop} {
      width: 5.4rem;
      height: 5.4rem;
    }

    @media ${device.desktop} {
      width: 6.2rem;
      height: 6.2rem;
    }
  `};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem;

  a {
    flex: 1;
    width: fit-content;
    max-width: 30rem;
  }

  button {
    width: 100%;
    white-space: nowrap;
  }

  @media ${device.laptopL} {
    margin-top: 10rem;
  }

  @media ${device.laptop} {
    justify-content: flex-start;
  }
`;

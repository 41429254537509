import styled, { css } from 'styled-components';
import { device } from '../../../../styles/media';
import { Section as SectionCommon } from '../../shared/Common';
import background from '../../../../../static/images/circles.svg';

export const Wrapper = styled.div`
  background: url(${background}) no-repeat;
`;

export const Section = styled(SectionCommon)`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr;
    background-position: left bottom;
    overflow-y: hidden;

    h2 {
      font-weight: 600;
      font-style: italic;
      color: ${theme.colors.green};
      word-break: break-word;
      max-width: 40rem;
      padding: 2rem 0;
    }

    @media ${device.laptop} {
      gap: 2.4rem;
      grid-template-columns: 30% 1fr;
    }
  `};
`;

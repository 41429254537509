import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { useIntl, Link } from 'gatsby-plugin-intl';

import Title from '../../../Title';
import { Button } from '../../../Button';

import * as S from './styles';

const img1 = '../../../../../static/exactalovers/Image1.jpg';
const img2 = '../../../../../static/exactalovers/Image2.jpg';
const img3 = '../../../../../static/exactalovers/Image3.jpg';
const img4 = '../../../../../static/exactalovers/Image4.jpg';
const img5 = '../../../../../static/exactalovers/Image5.jpg';

const IMG_WIDTH_VERTICAL = 400;

export type ImageProps = {
  width?: string;
  src: string;
};

export type SquareProps = {
  color: 'pink' | 'green' | 'blue';
};

export function Exactalovers() {
  const intl = useIntl();

  return (
    <S.Section id="exactalovers">
      <S.ContentWrapper>
        <S.Mosaic>
          <S.Line>
            <S.Square color="green" />

            <S.Column>
              <S.Square color="blue" />

              <S.ImageWrapper className="person_square">
                <StaticImage
                  src={img1}
                  alt="foto da colaboradora Rebeca no escritório da Exactaworks"
                  width={IMG_WIDTH_VERTICAL}
                  imgClassName="imgPosition"
                />
              </S.ImageWrapper>
            </S.Column>

            <S.ImageWrapper className="person_rectangle">
              <StaticImage
                src={img2}
                alt="foto do colaborador Eder no escritório da Exactaworks"
                width={IMG_WIDTH_VERTICAL}
              />
            </S.ImageWrapper>

            <S.Square color="pink" className="square3" />
          </S.Line>

          <S.Line>
            <S.ImageWrapper className="person_square">
              <StaticImage
                src={img3}
                alt="foto do colaborador Roberto no escritório da Exactaworks"
                width={IMG_WIDTH_VERTICAL}
                imgClassName="imgPosition"
              />
            </S.ImageWrapper>

            <S.ImageWrapper className="person_rectangle">
              <StaticImage
                src={img4}
                alt="foto da colaboradora Jaqueline no escritório da Exactaworks"
                width={IMG_WIDTH_VERTICAL}
              />
            </S.ImageWrapper>

            <S.Column>
              <S.ImageWrapper className="person_square">
                <StaticImage
                  src={img5}
                  alt="foto do colaborador Marcio no escritório da Exactaworks"
                  imgClassName="imgPosition"
                  width={IMG_WIDTH_VERTICAL}
                />
              </S.ImageWrapper>

              <S.Square color="blue" />
            </S.Column>
          </S.Line>
        </S.Mosaic>

        <S.TextContainer>
          <Title
            elementTag="h2"
            tag="h6"
            color="green"
            align="center"
            weight="bold"
          >
            {intl.formatMessage({ id: 'exactalovers-section' })}
          </Title>

          <Title align="center" tag="h5" elementTag="h3">
            {intl.formatMessage({ id: 'exactalovers-main' })}
            <span>{intl.formatMessage({ id: 'exactalovers-emphasis' })}</span>
            {intl.formatMessage({ id: 'exactalovers-minor' })}
          </Title>

          <S.ButtonWrapper>
            <Link to="/exacta-lovers/">
              <Button outlined size="medium">
                {intl.formatMessage({ id: 'exactalovers-redirect' })}
              </Button>
            </Link>

            <a
              href="https://jobs.exactaworks.com.br/jobs"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              <Button size="medium">
                {intl.formatMessage({ id: 'exactalovers-button' })}
              </Button>
            </a>
          </S.ButtonWrapper>
        </S.TextContainer>
      </S.ContentWrapper>
    </S.Section>
  );
}

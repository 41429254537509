import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import Text, { TextProps } from '../Text';
import { Quotes } from '../Icons/quotes';
import { ChevronRight } from '../Icons/chevronright';

import * as S from './styles';

type CarouselText = {
  main: string;
  emphasis?: string;
  minor?: string;
  name?: string;
  position?: string;
  alt?: string;
  image?: string;
};

export type CarouselProps = {
  type?: 'slide' | 'deposition';
  texts: CarouselText[];
};

export type Align = {
  align?: 'left' | 'center' | 'right';
};

function animateFadein(element?: HTMLElement | null) {
  element?.animate([{ opacity: 0 }, { opacity: 1 }], {
    duration: 500,
    iterations: 1
  });
}

export function SlideShow({
  texts,
  size,
  align = 'center',
  className,
  type = 'slide',
  weight
}: CarouselProps & Align & Pick<TextProps, 'size' | 'className' | 'weight'>) {
  const [index, setIndex] = useState(0);
  const contentWrapperRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();

  const handleItem = useCallback((id: number) => {
    setIndex(id);

    animateFadein(contentWrapperRef.current);
  }, []);

  useEffect(() => {
    const id = setTimeout(() => {
      handleItem((index + 1) % texts.length);
    }, 6000);

    return () => clearTimeout(id);
  }, [handleItem, index, texts.length]);

  return (
    <S.Wrapper align={align} type={type}>
      <S.WrapperContent type={type}>
        <S.SliderContent ref={contentWrapperRef} type={type}>
          <Text size={size} weight={weight} className={className}>
            <>
              {intl.formatMessage(
                { id: texts[index].main },
                {
                  color: chunks => <span>{chunks}</span>
                }
              )}

              {texts[index].minor &&
                intl.formatMessage({ id: texts[index].minor })}
            </>
          </Text>
        </S.SliderContent>

        <ul className="dots">
          {texts.map((_item, id) => (
            <li key={id}>
              <label
                className={id === index ? 'active' : 'disable'}
                onClick={() => handleItem(id)}
              />
            </li>
          ))}
        </ul>
        {type === 'deposition' && (
          <>
            <Quotes className="quotes" />

            <S.Link className="join-team">
              <a
                href="https://jobs.exactaworks.com.br/jobs"
                target="_blank"
                rel="noreferrer"
              >
                <Text size="xmedium" weight="medium" color="green">
                  <i>{intl.formatMessage({ id: 'depositions-join-team' })}</i>
                </Text>
              </a>

              <ChevronRight />
            </S.Link>
          </>
        )}
      </S.WrapperContent>

      {type === 'deposition' && (
        <S.DepositionWrapper>
          <S.Image src={texts[index].image} alt={texts[index].alt} />

          <Text size="xxmedium" weight="medium">
            — {texts[index].name}
          </Text>

          <Text tag="p" size="xmedium" className="details">
            {intl.formatMessage({ id: texts[index].position })}
          </Text>
        </S.DepositionWrapper>
      )}
    </S.Wrapper>
  );
}

import React from 'react';

type Props = {
  className?: string;
};

export function Quotes({ className }: Props) {
  return (
    <svg
      width="50"
      height="36"
      viewBox="0 0 50 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M11.9205 36C9.27152 36 7.02723 35.3818 5.18764 34.1455C3.42163 32.9091 2.09713 31.2727 1.21413 29.2364C0.40471 27.2 0 25.0182 0 22.6909C0 19.3455 0.699043 16.1818 2.09713 13.2C3.5688 10.2182 5.44518 7.6 7.72627 5.34546C10.0809 3.01818 12.6196 1.23637 15.3422 0L22.9581 5.56364C20.9713 6.43637 19.0949 7.49091 17.3289 8.72727C15.5629 9.89091 14.0177 11.2 12.6932 12.6545C11.4422 14.1091 10.4857 15.6 9.8234 17.1273L10.3753 17.4545C10.8168 17.1636 11.2951 16.9455 11.8102 16.8C12.3988 16.6545 13.1714 16.5818 14.128 16.5818C15.4525 16.5818 16.777 16.9091 18.1015 17.5636C19.4996 18.2182 20.6402 19.2 21.5232 20.5091C22.4798 21.8182 22.9581 23.4909 22.9581 25.5273C22.9581 27.7818 22.443 29.7091 21.4128 31.3091C20.3826 32.8364 19.0213 34 17.3289 34.8C15.6365 35.6 13.8337 36 11.9205 36ZM38.9625 36C36.3135 36 34.0692 35.3818 32.2296 34.1455C30.4636 32.9091 29.1391 31.2727 28.2561 29.2364C27.4467 27.2 27.0419 25.0182 27.0419 22.6909C27.0419 19.3455 27.741 16.1818 29.1391 13.2C30.6107 10.2182 32.4871 7.6 34.7682 5.34546C37.1229 3.01818 39.6615 1.23637 42.3841 0L50 5.56364C48.0132 6.43637 46.1369 7.49091 44.3709 8.72727C42.6049 9.89091 41.0596 11.2 39.7351 12.6545C38.4842 14.1091 37.5276 15.6 36.8653 17.1273L37.4172 17.4545C37.8587 17.1636 38.337 16.9455 38.8521 16.8C39.4408 16.6545 40.2134 16.5818 41.17 16.5818C42.4945 16.5818 43.819 16.9091 45.1435 17.5636C46.5416 18.2182 47.6821 19.2 48.5651 20.5091C49.5217 21.8182 50 23.4909 50 25.5273C50 27.7818 49.4849 29.7091 48.4547 31.3091C47.4246 32.8364 46.0633 34 44.3709 34.8C42.6784 35.6 40.8756 36 38.9625 36Z"
        fill="#03FFCB"
      />
    </svg>
  );
}

import styled, { css } from 'styled-components';
import { device } from '../../../../../styles/media';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 180rem;
  gap: 2rem;

  @media ${device.laptop} {
    gap: 8rem;
  }
`;

export const ServicesContainer = styled.div`
  ${({ theme }) => css`
    gap: 2rem;

    .green {
      background-color: ${theme.colors.green};
    }

    .blue {
      background-color: ${theme.colors.darkblue};
    }

    .pink {
      background-color: ${theme.colors.pink};
    }

    p {
      text-align: left;
      font-weight: ${theme.font.light};
    }

    span {
      text-align: left;
      font-weight: ${theme.font.bold};
    }

    :nth-child(2n) {
      flex-direction: row-reverse;

      h3 {
        background: ${theme.colors.pink};
      }
    }

    @media ${device.laptop} {
      display: flex;
      flex-direction: row;
      gap: 4rem;

      :nth-child(2n) {
        flex-direction: row-reverse;
        padding: 0 0 0 8%;

        h3 {
          background: none;
        }
      }

      div:last-child {
        div {
          align-self: start;
        }
      }
    }
  `}
`;

export const ImageContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;

  div {
    display: none;
  }

  img {
    width: 100%;
  }

  @media ${device.laptop} {
    div {
      flex-direction: column;
      display: flex;
    }

    .green {
      align-self: end;
    }

    .blue {
      position: absolute;
      top: -6rem;
    }

    .pink {
      align-self: start;
    }

    img {
      width: 44rem;
    }
  }

  @media ${device.laptopL} {
    img {
      width: 56rem;
    }
  }
`;

export const Square = styled.div`
  display: flex;
  width: 6rem;
  height: 6rem;
`;

export const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;

  @media ${device.laptop} {
    flex-direction: column;
    display: flex;
  }
`;

export const TitleContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    h3 {
      background: ${theme.colors.darkblue};
      padding: 2rem;
    }

    div {
      display: flex;
    }

    @media ${device.laptop} {
      flex-direction: column;
      display: none;
    }
  `}
`;

export const TextWrapper = styled.div``;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;

  h3 {
    display: none;
    text-align: left;
  }

  a {
    display: flex;
    align-self: center;
    text-decoration: none;
  }

  @media ${device.laptop} {
    gap: 4rem;

    a {
      align-self: end;
    }

    h3 {
      display: block;
    }
  }
`;

import type { HeadFC } from 'gatsby';
import * as React from 'react';
import { ThemeProvider } from 'styled-components';

import GlobalStyle from '../styles/global';
import Theme from '../styles/theme';
import { Home } from '../components/pages/Home';
import Seo from '../components/Seo';

const pageStyles = {
  fontFamily: 'Work Sans, -apple-system, sans-serif'
};

const IndexPage = () => {
  return (
    <div style={pageStyles}>
      <ThemeProvider theme={Theme}>
        <GlobalStyle />

        <Home />
      </ThemeProvider>
    </div>
  );
};

export default IndexPage;

export const Head: HeadFC = () => (
  <Seo title="Powered by People" canonical="" />
);
